/* eslint-disable no-undef */
import React from "react";

const HeaderLeft = () => (
  <div className="main-header__left">
    <a href={enerventNav.homeUrl}>
      <img
        src="/wp-content/themes/enervent/dist/images/ez.png"
        className="main-header__logo"
        alt="Enervent logo"
      />
    </a>
  </div>
);

export default HeaderLeft;
